.slider {
	width: 180px;
	outline: none;
	appearance: none;
	height: 3px;
	border-radius: 3px;
	background-color: #665d6e;
	cursor: pointer;
}

.slider::-webkit-slider-thumb {
	appearance: none;
	background-color: #ffffff;
	width: 13px;
	height: 13px;
	border-radius: 13px;
}

.progress-circle {
	background-color: white;
	width: 13px;
	height: 13px;
	border-radius: 3px;
	animation: spin 5s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
