
:root {
	--tweet-white: rgb(180, 180, 180);
	--tweet-red: var(--lighter-red);
	--tweet-blue: var(--lighter-blue);
}

.tweet {
	border: 1px solid var(--tweet-white);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	padding: 10px 15px 5px 15px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
	user-select: none;
	text-decoration: none;
	color: white;
	margin: 10px;
}

.tweet-icon {
	user-select: none;
	pointer-events: none;
	font-size: 20px;
}

.tweet-header {
	padding-bottom: 10px;
	display: flex;
	align-items: center;
}

.tweet-profile {
	width: 44px;
	height: 44px;
	border: 2px solid var(--tweet-white);
	border-radius: 44px;
}

.tweet-text {
	flex-grow: 1;
	color: white;
	user-select: all;
	cursor: text;
	white-space: pre-wrap;
}

.tweet-time {
	color: var(--tweet-white);
	margin-top: 5px;
	display: flex;
	align-items: center;
	height: 28px;
	font-size: 14px;
}

.tweet-footer {
	padding-top: 5px;
	margin-top: 5px;
	border-top: 1px solid var(--tweet-white);
	height: 30px;
	display: flex;
	align-items: center;
	color: var(--tweet-white);
	font-size: 14px;
	overflow: hidden;
}

.tweet-style {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}