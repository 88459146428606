.wrapper {
	position: relative;
}

.left, .right {
	position: absolute;
	padding: 0;
	margin: 0;
}

.right {
	animation: right 10s linear infinite;
}

.left {
	animation: left 10s linear infinite;
}

.block {
	position: absolute;
	z-index: 100;
	animation: spin 10s linear infinite;
	left: 50;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes left {
	0% { opacity: 0; }
	49.9% { opacity: 0; }
	50% { opacity: 1; }
	100% { opacity: 1; }
}

@keyframes right {
	0% {z-index: 0;}
	49.9% {z-index: 0;}
	50% {z-index: 500;}
	100% {z-index: 500;}
}
