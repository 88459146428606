:root {
  --background: black;
  --chart-background: #0c0c0c;
  --blue: #2e3aff;
  --light-blue: #3ac0ff;
  --lighter-blue: #78b6d3;
  --lighter-red: #c46f79;
  --light-red: #ff808f;
  --red: #ff5050;
}

#app {
  background-color: var(--background);
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}

#page {
  margin: 110px 100px 200px 100px;
  color: white;
  font-family: 'Amiri', serif;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

#page > * {
  width: 700px;
}

#title {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
}

#subtitle {
  font-size: 23px;
  line-height: 1.4;
  margin-bottom: 5px;
}

#author {
  opacity: 0.7;
  font-size: 1.1rem;
}

.heading {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 40px;
}

.content {
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 7px;
  margin-bottom: 20px;
}

.chart {
  /* width: 70vw !important; */
  /* width: 1000px !important; */
  min-width: 700px;
  border: 1px solid rgb(59, 59, 59);
  background-color: var(--chart-background);
  border-radius: 10px;
  margin: 10px 0 30px 0;
}

.link-no-styling {
  text-decoration: none;
  color: inherit;
}

.opacity-on-hover {
  transition-duration: 0.15s;
}

.opacity-on-hover:hover {
  opacity: 0.8 !important;
}

.opacity-transition {
  transition-duration: 0.15s;
}

.dark-link {
  color: rgb(212, 145, 243) !important;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.15;
  /* font-weight: bold; */
}

.dark-link:hover {
  opacity: 0.9 !important;
}

@media only screen and (max-width: 800px) {
  #page {
    margin-top: 50px;
  }
  #page > * {
    width: 90vw;
  }
  #title {
    font-size: 2rem;
  }
  .content {
    font-size: 1.3rem;
    line-height: 1.4;
  }
  .chart {
    width: 90vw !important;
    min-width: unset;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  transition-duration: 0.2s;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  transition-duration: 0.2s;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(146, 146, 146);
  border-radius: 4px;
  transition-duration: 0.2s;
}

.scroll:hover ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background: rgb(199, 199, 199);
  transition-duration: 0.2s;
}

